import { createRouter, createWebHistory } from 'vue-router';
import { signOut, doesSessionExist } from './composables/useAuth.js';

import DashboardPage from './pages/DashboardPage.vue';
import LoggedUserPage from './pages/LoggedUserPage.vue';
import LoginPage from './pages/LoginPage.vue';
import ProgramsPage from './pages/ProgramsPage.vue';
import ProgramPage from './pages/ProgramPage.vue';
import NotFoundPage from './pages/NotFoundPage.vue';

let authorizeResult = null;

async function isAuthorized() {
  if (authorizeResult === null) {
    const _isAuthorized = await doesSessionExist();
    authorizeResult = { isAuthorized: _isAuthorized };
  }

  return authorizeResult.isAuthorized;
}

const routes = [
  {
    path: '/',
    component: LoggedUserPage,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      {
        path: 'dashboard',
        component: DashboardPage
      },
      {
        path: 'programs',
        component: ProgramsPage
      },
      {
        path: 'program/:programId',
        component: ProgramPage,
        props: true
      },
      {
        path: 'program',
        component: ProgramPage,
        props: { programId: null }
      }
    ]
  },

  {
    path: '/login',
    component: LoginPage,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/dashboard');
      } else {
        next();
      }
    }
  },
  {
    path: '/logout',
    component: true, // NOTE: this is a hack to make the beforeEnter hook work
    beforeEnter: async () => {
      await signOut();
      router.push('/login');
    }
  },

  {
    path: '/404',
    name: 'NotFound',
    component: NotFoundPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.querySelector('#app');
    if (element) {
      element.scrollTo(0, 0);
      return null;
    }

    return { top: 0 };
  }
});

router.afterEach(() => {
  // reset the authorization check
  authorizeResult = null;
});

export default router;
