<template>
  <dropdown-default v-if="user" v-model="model" :items="items" :placement="isMobile ? 'bottom-end' : 'top-start'" :auto-placement="false">
    <div class="w-full flex items-center gap-x-4 text-sm font-semibold leading-6 text-gray-900 lg:px-6 lg:py-3 lg:hover:bg-gray-50">
      <img class="h-8 w-8 rounded-full bg-gray-50" :src="user.avatarUrl" alt="">
      <span class="sr-only">Your profile</span>
      <span class="hidden lg:block" aria-hidden="true">{{ user.email }}</span>
    </div>
  </dropdown-default>
</template>

<script setup>
import { watch } from 'vue';
import DropdownDefault from './DropdownDefault.vue';
import { isMobile } from '../composables/useBreakpoints';

defineProps({
  user: {
    type: Object,
    default: () => ({})
  },
  items: {
    type: Array,
    default: () => []
  }
});

const model = defineModel({ type: Object, default: null });

const emit = defineEmits([ 'clicked' ]);

watch(model, value => {
  if (value) {
    emit('clicked', value);
    model.value = null;
  }
});
</script>
