<template>
  <page-header title="Programs">
    <router-link
      to="/program"
      class="btn-primary ml-auto h-10"
    >
      <PlusCircleIcon class="size-5 -ml-1 mr-2" />
      Add
    </router-link>
  </page-header>

  <programs-empty v-if="isEmpty && search === null" />

  <template v-else>
    <div class="flex justify-between mb-4">
      <input-search v-model="search" class="max-w-lg grow" />

      <reload-button class="ml-4" @click="reloadGrid" />
    </div>

    <grid
      ref="gridRef"
      :data-handler="getPrograms"
      :columns="columns"
      :row-class="rowClass"
      @delete="onDelete"
      @enable="onEnable"
      @disable="onDisable"
    />
  </template>
</template>

<script setup>
import { ref, markRaw } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import ReloadButton from '../components/ReloadButton.vue';
import InputSearch from '../components/InputSearch.vue';
import ProgramsEmpty from '../components/ProgramsEmpty.vue';
import ProgramListActions from '../components/ProgramListActions.vue';
import ProgramIcon from '../components/ProgramIcon.vue';
import Grid from '../components/grid/Grid.vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { useFetch } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { apiDomain } from '../composables/useConstants';
import { Modal } from '../composables/useModal';
import { useToast } from 'vue-toastification';

const gridRef = ref(null);
const search = ref(null);
const isEmpty = ref(false);
const router = useRouter();
const toast = useToast();

const rowClass = (_row, index) => (index % 2 ? 'bg-zinc-300/10' : '') + (_row.deletedAt ? ' [&>td:not(:last-child)]:opacity-30' : ''); // stripes class for row

function reloadGrid() {
  if (gridRef.value?.reload) {
    gridRef.value.reload();
  }
}

function getUrl({ limit, offset }) {
  const data = { limit, offset };

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/program/?${searchParams}`;
}

async function getPrograms(args) {
  console.log('getPrograms', args);

  // FIXME: add order and search
  const { limit, offset } = args;
  const { data } = await useFetch(getUrl({ limit, offset })).get().json();

  isEmpty.value = false;

  return {
    total: 12,
    rows: data.value
  };
}

const columns = [
  {
    title: '#',
    prop: 'id',
    titleClass: '', // class for title cell
    sortable: true
  },
  {
    title: 'Name',
    prop: 'name',
    component: markRaw(ProgramIcon),
    class: '!py-0',
    titleClass: '!pl-14',
    click: row => router.push(`/program/${row.id}`),
    sortable: true
  },
  {
    title: 'Category',
    value: row => row.category.name,
    click: row => search.value = row.category.name,
    sortable: true
  },
  {
    title: 'Type',
    prop: 'licenseType',
    sortable: true
  },
  {
    title: 'Developer',
    value: row => row.developer.name,
    click: row => search.value = row.developer.name,
    sortable: true
  },
  {
    title: 'Publisher',
    value: row => row.publisher.name,
    click: row => search.value = row.publisher.name,
    sortable: true
  },
  {
    class: 'no-animation text-right !max-w-max z-10 relative',
    component: markRaw(ProgramListActions)
  }
];

async function onDelete(row) {
  const confirm = await Modal.confirm({
    title: 'Delete Program',
    body: `Are you sure you want to delete the program "${row.name}"?`,
    okButton: 'Delete',
    iconColor: 'primary'
  });

  if (!confirm) {
    return;
  }

  const { data } = await useFetch(`${apiDomain}/api/program/${row.id}`).delete().json();

  if (data.value?.success) {
    toast.success('Program deleted successfully');
    reloadGrid();
    return;
  }

  toast.error('Error deleting program');
}

async function onEnable(row) {
  const { data } = await useFetch(`${apiDomain}/api/program/enable/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('Program enabled successfully');
    reloadGrid();
    return;
  }

  toast.error('Error enabling program');
}

async function onDisable(row) {
  const { data } = await useFetch(`${apiDomain}/api/program/disable/${row.id}`).get().json();

  if (data.value?.success) {
    toast.success('Program disabled successfully');
    reloadGrid();
    return;
  }

  toast.error('Error disabling program');
}
</script>
